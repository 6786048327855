import React, { useState, useEffect } from 'react';
import './RegistrationForm.css';
import Logo from '../utils/Logo';
import Select from 'react-select';
import createUser from '../API/createUser';
import fetchGroups from '../API/fetchGroup';
import fetchIsoCodes from '../API/fetchIso';
import { OKTA_URL } from '../config';
import { OKTA_HELP_URL } from '../config';
import { validateEmail } from '../helper/emailValidator';
import createRequest from '../API/createRequest';
import readUserLocation from '../helper/readUserLocation';

const RegistrationForm = ({ onRegistrationSuccess }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        distributorPartner: '',
    });

    const [fieldErrors, setFieldErrors] = useState({});
    const [groups, setGroups] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [error, setError] = useState('');

    // Loading the ISO codes during the first rendering
    useEffect(() => {
        fetchIsoCodes()
            .then(isoCodes => {
                setCountryOptions(isoCodes);
            })
            .catch(error => {
                console.error('Error fetching ISO codes:', error);
                setError('Failed to fetch country codes');
            });
    }, []);

    // Standort des Benutzers ermitteln und Land automatisch setzen
    useEffect(() => {
        readUserLocation().then(location => {
            if (location && location.countryRegion) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    country: location.countryRegion.isoCode,
                }));
            }
        });
    }, []); // Dies sorgt dafür, dass die Funktion nur einmal ausgeführt wird

    // Loading the groups based on the selected country
    useEffect(() => {
        if (formData.country) {
            fetchGroups(formData.country)
                .then(data => {
                    if (data.length === 0) {
                        setError('No organization is currently registered in your country. Please reach out your administrator.');
                        setGroups([]);
                    } else {
                        setError('');
                        setGroups(data.map(group => ({ value: group.id, label: group.profile.name })));
                    }
                })
                .catch(error => {
                    console.error('Error fetching groups:', error);
                    setError('Failed to fetch distributor partners');
                });
        } else {
            setGroups([]);
        }
    }, [formData.country]);

    // Updating the form data and correcting field-specific errors
    const handleInputChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // Clear field-specific error
        setFieldErrors({ ...fieldErrors, [e.target.name]: '' });
    };

    // Update the selected options and clean up the field-specific errors
    const handleSelectChange = (selectedOption, action) => {
        if (action.name === 'country') {
            setFormData({ ...formData, distributorPartner: '', [action.name]: selectedOption ? selectedOption.value : '' });
            setFieldErrors({ ...fieldErrors, distributorPartner: '' }); //  Delete field-specific error for distributorPartner
        } else {
            setFormData({ ...formData, [action.name]: selectedOption ? selectedOption.value : '' });
        }
        // Delete field-specific error
        setFieldErrors({ ...fieldErrors, [action.name]: '' });
    };

    // Form submission function
    const handleSubmit = async e => {
        e.preventDefault();

        let newErrors = {};
        let isFormFullyFilled = true;

        // Check that all fields are filled in
        Object.keys(formData).forEach(key => {
            if (!formData[key].trim()) {
                isFormFullyFilled = false;
                newErrors[key] = 'This field is required'; // Mark missing fields
            }
        });

        if (!isFormFullyFilled) {
            // If not all fields are filled in, display this error message
            setFieldErrors(newErrors);
            setError('All fields are required. Please complete the missing information.');
            return;
        }

        // Now check the email validity
        if (!validateEmail(formData.email)) {
            // Especially for the e-mail field, if invalid
            newErrors.email = 'Please enter a valid email address.';
            setFieldErrors(newErrors);
            setError('Please enter a valid email address.');
            return;
        }

        // If there are no errors, clear the error messages and continue the attempt to create the user
        setError('');
        setFieldErrors({}); // Clean up all field errors, as the validation was successful

        try {
            const userResponse = await createUser({
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                groupId: formData.distributorPartner,
            });

            if (userResponse.success) {
                const requestResponse = await createRequest(userResponse.data.id, formData.distributorPartner);
                if (requestResponse && requestResponse.status >= 200 && requestResponse.status < 300) {
                    onRegistrationSuccess(true);
                } else {
                    onRegistrationSuccess(false);
                    setError('Failed to create additional request.');
                    return; // Terminates the function early to prevent further execution
                }
            } else {
                // Here we check the specific error message and react accordingly
                if (userResponse.message === 'login: An object with this field already exists in the current organization') {
                    setError(
                        <span>
                            This address is already linked to an existing account. To continue, <a href={OKTA_URL}>log in</a>.
                        </span>,
                    );
                } else {
                    // For all other errors, we use the error message as it is
                    setError(userResponse.message || 'Registration failed. Please try again.');
                }
            }
        } catch (error) {
            console.error('Error during registration:', error);
            setError(error.message || 'Registration failed. Please try again.');
            onRegistrationSuccess(false);
        }
    };

    // Custom styles for the select element based on errors
    const getCustomStyles = fieldName => ({
        control: (provided, state) => ({
            ...provided,
            borderColor: fieldErrors[fieldName] ? 'red' : provided.borderColor,
            '&:hover': { borderColor: fieldErrors[fieldName] ? 'darkred' : provided['&:hover'].borderColor },
            color: '#000F1E',
            fontSize: '14px',
            fontWeight: 'normal',
        }),
        option: (provided, state) => ({
            ...provided,
            color: '#000F1E',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'rgba(0, 15, 30, 0.4)',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#000F1E',
        }),
    });

    return (
        <div className="registration-form-container">
            <form className="registration-form" onSubmit={handleSubmit} noValidate>
                <div className="registration-form-logo-placeholder">
                    <Logo />
                </div>

                <div className="account-text-container">
                    <p className="signup-text">Sign Up</p>
                    <p className="account-hint">
                        Already have an account? <a href={OKTA_URL}>Sign In</a>
                    </p>
                </div>

                <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    className={fieldErrors.firstName ? 'input-error' : ''}
                    value={formData.firstName}
                    onChange={handleInputChange}
                />
                <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    className={fieldErrors.lastName ? 'input-error' : ''}
                    value={formData.lastName}
                    onChange={handleInputChange}
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className={fieldErrors.email ? 'input-error' : ''}
                    value={formData.email}
                    onChange={handleInputChange}
                />
                <Select
                    name="country"
                    options={countryOptions}
                    value={countryOptions.find(option => option.value === formData.country)}
                    onChange={handleSelectChange}
                    placeholder="Country"
                    classNamePrefix="select"
                    styles={getCustomStyles('country')} // Applying customStyles based on the country field
                />
                {formData.country && (
                    <Select
                        name="distributorPartner"
                        options={groups}
                        value={groups.find(option => option.value === formData.distributorPartner) || null}
                        onChange={handleSelectChange}
                        placeholder="Organization"
                        classNamePrefix="select"
                        styles={getCustomStyles('distributorPartner')} // Apply customStyles based on DistributorPartner field
                    />
                )}
                {error && <p className="form-error">{error}</p>}
                <button type="submit" className="button">
                    Complete Registration
                </button>
                {/* Please insert the links here */}
                <div className="form-footer">
                    <p>
                        By completing registration, you accept <a href="/terms-of-use">Terms of Use</a> and{' '}
                        <a href="/privacy-policy">Privacy Policy</a>.{' '}
                    </p>
                </div>
                <div style={{ marginTop: '0px' }}>
                    <a href={OKTA_HELP_URL}>Help</a>
                </div>
            </form>
        </div>
    );
};

export default RegistrationForm;
