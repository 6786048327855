// Funktion, um den Standort des Benutzers mithilfe von Azure Maps zu ermitteln, über eine Azure Function
const readUserLocation = async () => {
    try {
        // URL der Azure Function, die die Geolocation basierend auf der IP-Adresse abruft
        const functionUrl = 'https://rb-localisierung-test.azurewebsites.net/api/userLocalation?code=I7lqUdfYFH29jgonWmkXo5Q-qlM59NhhWCZmgRD0KbC6AzFuFyKY1g=='; // Die URL deiner Azure Function hier einsetzen

        // Anfrage an die Azure Function
        console.log('Request URL an Azure Function:', functionUrl);
        const response = await fetch(functionUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            const data = await response.json();
            console.log('Erhaltene Standortdaten von der Azure Function:', data);

            if (data.countryRegion.isoCode === 'US') {
                return data
            } else {
                return null;
            }

        } else {
            console.error('Fehler beim Abrufen des Standorts von der Azure Function:', response.status, response.statusText);
            return null;
        }
    } catch (error) {
        console.error('Fehler bei der Standortabfrage von der Azure Function:', error);
        return null;
    }
};

export default readUserLocation;